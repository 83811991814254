import React from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEmployee } from "../../../app/hooks/useEmployee";
import { toast } from "react-toastify";
import { usePositions } from "../../../app/hooks/usePositions";
import { useDepartment } from "../../../app/hooks/useDepartment";
import { useRegions } from "../../../app/hooks/useRegions";
import { format } from "date-fns";

const UpdateEmploymentInfoForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  UpdateEmploymentInfoForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  const { update } = useEmployee();
  const { updateEmployeeStore, modalStore } = useStore();
  const { personalInfoFormData, setPersonalInfo } = updateEmployeeStore;
  const {
    setUpdateEmployeeDetailsModalVisible,
    setUpdateEmploymentInfoModalVisible,
  } = modalStore;

  const handleBack = () => {
    setUpdateEmployeeDetailsModalVisible(true);
    setUpdateEmploymentInfoModalVisible(false);
  };

  const { data: departmentList } = useDepartment({
    pageNumber: 0,
    pageSize: 9999,
    sort: "date_descend",
  });
  const { data: positionList } = usePositions({
    pageNumber: 0,
    pageSize: 9999,
    sort: "date_descend",
  });
  const { data: regionList } = useRegions();
  const formattedDateHired = format(
    new Date(personalInfoFormData?.serviceRecords[0]?.dateHired),
    "yyyy-MM-dd"
  );

  const statusEnum = [
    { value: 0, description: "Inactive" },
    { value: 1, description: "Active" },
    { value: 2, description: "Blocked" },
    { value: 3, description: "Separated" },
  ];

  const getStatusDescription = (value) => {
    const status = statusEnum.find((status) => status.value === value);
    return status ? status.value : undefined;
  };

  const initialValues = {
    ...personalInfoFormData,
    position: { 
      value: personalInfoFormData?.serviceRecords[0]?.positionId, 
      label: personalInfoFormData?.serviceRecords[0]?.positionName 
    },
    employeeNum: personalInfoFormData?.employeeNumber,
    dateHired: formattedDateHired,
    workAss: personalInfoFormData?.serviceRecords[0]?.regionName,
    employmentStatus: personalInfoFormData?.serviceRecords[0]?.employmentStatus,
    department: {
      value: personalInfoFormData?.serviceRecords[0]?.departmentId,
      label: personalInfoFormData?.serviceRecords[0]?.departmentName,
    },
    contractType: personalInfoFormData?.serviceRecords[0]?.contractType,
  };

  const handleSubmit = async () => {
    try {
      const formValues = form.getFieldsValue();

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    }

      const updateFormData = {
        ...personalInfoFormData,
        employeeNumber: formValues.employeeNum || personalInfoFormData?.employeeNumber,
        serviceRecords: [
          {
            ...personalInfoFormData?.serviceRecords[0],
            employeeId: personalInfoFormData?.id,
            dateHired: formatDate(formValues.dateHired) || formattedDateHired,
            positionId: formValues.position?.value || personalInfoFormData?.serviceRecords[0]?.positionId,
            positionName: formValues.position?.label || personalInfoFormData?.serviceRecords[0]?.positionName,
            regionName: formValues.workAss || personalInfoFormData?.serviceRecords[0]?.regionName,
            employmentStatus:formValues.employmentStatus || personalInfoFormData?.serviceRecords[0]?.employmentStatus,
            departmentId: formValues.department?.value || personalInfoFormData?.serviceRecords[0]?.departmentId,
            departmentName: formValues.department?.label || personalInfoFormData?.serviceRecords[0]?.departmentName,
            contractType: formValues.contractType || personalInfoFormData?.serviceRecords[0]?.contractType,
            preEmploymentRequirementStatus:  formValues.preEmployRequirements === "true" ? true : false,
            status: formValues.activeOrInactive === 0 ? 0 : formValues.activeOrInactive,
          },
        ],
      };
      setPersonalInfo(updateFormData);
      await update(updateFormData)
        .then(() => {
          setUpdateEmployeeDetailsModalVisible(true);
          setUpdateEmploymentInfoModalVisible(false);
          onSubmit();
          toast.success("Successfully Update Employment Information", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            // Extract error titles and display them in toasts
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            // Display a generic error message
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };
  const { Option } = Select;

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
      >
        <h2 className="core-form-title">
          II. <span style={{ color: "#FF7201" }}>Employment </span>Information
        </h2>
        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              name="position"
              label="Position:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select
                allowClear
                labelInValue
                options={positionList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Employee Number:"
              name="employeeNum"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Date Hired:"
              name="dateHired"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Work Assignment:"
              name="workAss"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                placeholder="Region"
                allowClear
                options={regionList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"}>
          <Col span={8}>
            <Form.Item
              label="Employment Status:"
              name="employmentStatus"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="JobOrder">Job Order</Option>
                <Option value="ProjectBased">Project Based</Option>
                <Option value="Regular">Regular</Option>
                <Option value="Separated">Separated</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Pre Employment Requirements:"
              name="preEmployRequirements"
              rules={[{ required: true, message: "Required" }]}
              initialValue={personalInfoFormData?.serviceRecords[0]?.preEmploymentRequirementStatus?.toString()}
            >
              <Select allowClear>
                <Option value="true">True</Option>
                <Option value="false">False</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Active or Inactive:"
              name="activeOrInactive"
              rules={[{ required: true, message: "Required" }]}
              initialValue={getStatusDescription(
                personalInfoFormData?.serviceRecords[0]?.status
              )}
            >
              <Select allowClear>
                {statusEnum.map((status) => (
                  <Option key={status.value} value={status.value}>
                    {status.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              name="department"
              label="Department:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select
                allowClear
                labelInValue
                options={departmentList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Contact Type:"
              name="contractType"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="NotApplicable">Not Applicable</Option>
                <Option value="ShortTerm">Short Term</Option>
                <Option value="LongTerm">Long Term</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Update
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={handleBack}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(UpdateEmploymentInfoForm);
